<template>
    <div class="max-w-screen-md mx-auto">
      <div class="bg-white shadow-md rounded-lg">
        <button
          @click="toggleOpen"
          class="flex justify-between w-full p-6 tracking-wider text-lg font-medium text-left text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
        >
          <span>{{ title }}</span>
          
        </button>
        <div v-if="isOpen" class="px-4 py-6 text-lg text-gray-500">
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  
  const props = defineProps({
    title: {
      type: String,
      required: true
    }
  })
  
  const isOpen = ref(false)
  
  const toggleOpen = () => {
    isOpen.value = !isOpen.value
  }
  </script>