<script setup lang="ts">
import type { BlobObject } from "@nuxthub/core"
useHead({
    title: "IMAGE AI"
})
const faceSwap: Ref< string > = ref('/default_img.png')
const target: Ref< string > = ref('/default_img.png')
const complete: Ref< string > = ref('https://img.stickerbotai.com/default_swap.png')
const taskId = ref('')
const vDiv = ref(false)
const initComplete = ref(true)
const toast = useToast()
const upload = useUpload('/api/upload', {method: "PUT"})

async function onFileSelect(t: string) {
    const a = document.createElement("input")
    a.setAttribute("type", "file")
    a.setAttribute("name", "files")
    a.addEventListener("change", async function(event){
        const uploadedFiles: BlobObject[] = await upload(event.target as HTMLInputElement)
        console.log(uploadedFiles)
        let src  = `https://face.stickerbotai.com/${uploadedFiles[0].pathname}`
        if(t == 'face-swap'){
            faceSwap.value = src
        }else{
            target.value = src
        }
    })
    a.click()
}

interface ResponseData{
    status: string;
    task_id: string;
}

const handlerFaceSwap = async () => {
    const formData = new FormData();
    if(faceSwap.value == '' || target.value == '') {
        toast.add({title:"please upload origin & target photo"})
        console.log("Please upload a photo")
        return false
    }
    formData.append('swap', faceSwap.value);
    formData.append('target', target.value);

    //set target photo 
    complete.value = target.value
    vDiv.value = true
    //debug
    //return true
    const { data } = await useFetch('/api/swap',{
        method: 'POST',
        body: formData
    })
    const responseData: ResponseData = data.value as ResponseData
    taskId.value = responseData.task_id
    checkTask()
}

interface Task{
    status: string;
    complete: string;
}

const checkTask = async () => {
    const formData = new FormData()
    formData.append('task_id', taskId.value);
    const { data } = await useFetch<Task>('/api/task', {method: 'POST', body: formData})
    if(data?.value?.status == 'success'){
        complete.value = data?.value?.complete
        vDiv.value = false
        return
    }else if (!data || data.value == null || data.value.status == 'fail') {
        console.log('Task failed')
        return
    }
    setTimeout(checkTask, 2000)
}

const handlerCssStyle = () => {
    return {
        'size-6': 'w-6 h-6',
        'size-24': 'w-24 h-24'
    }
}
const  handlerClick = () => {
    console.log('click')
    throw new Error("click ....")
}
</script>

<template>
<div class="mt-28">
    <div class="flex flex-row gap-8 mx-auto">
        <div class=" flex flex-col">
            <div class="text-md text-gray-800 mb-4">STEP 1 <span class="text-md text-gray-500">upload a origin photo with a face</span> </div>
            <div class=" border border-solid border-gray-100 p-6 shadow-md rounded-xl flex items-center justify-center w-80 h-96">
                <img v-if="target" :src="target" id="target" class="w-full object-cover rounded-full" />
                
            </div>
            <div @click="onFileSelect('target')" class="flex justify-center border border-solid rounded-3xl p-2 px-10 m-2 mt-6 bg-gray-500 text-white hover:bg-gray-800 hover:cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                    </svg>
                    <span class="ml-2">Upload</span>
            </div>
        </div>
        <div class=" flex flex-col">
            <div class="text-md text-gray-800 mb-4">STEP 2 <span class="text-md text-gray-500">upload a origin photo with a face</span> </div>
            <div class=" border border-solid border-gray-100 p-6 shadow-md rounded-xl flex items-center justify-center w-80 h-96">
                <img v-if="target" :src="faceSwap" id="face-swap" class="w-full object-cover rounded-full" />
                
            </div>
            <div @click="onFileSelect('face-swap')" class="flex justify-center border border-solid rounded-3xl p-2 px-10 m-2 mt-6 bg-gray-500 text-white hover:bg-gray-800 hover:cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                    </svg>
                    <span class="ml-2">Upload</span>
            </div>
        </div>
        <div class="flex justify-center items-center">
                <button @click="handlerFaceSwap" class="border border-solid bg-gray-500  rounded-3xl p-2 px-10 m-2 hover:bg-gray-800 text-white">Swap</button>
        </div>
        <div class=" h-fit flex justify-center">
            <div class="p-10 flex flex-col items-center justify-center">
                    <div class="backdrop-blur-md bg-white/30 relative">
                        <div v-if="vDiv" class="absolute top-0 left-0 w-full h-full bg-white/30 backdrop-blur-md text-white flex justify-center items-center"> Generating ...</div>
                        <img :src="complete" class="w-80 h-96 object-cover rounded-md border border-red-100"/>
                        
                    </div>
                    <div class="flex justify-center mt-4">
                        <button class="border border-solid invisible  rounded-3xl p-2 px-10 m-2 hover:border-green-300 hover:text-green-300">Share</button>
                        <button class="border border-solid  rounded-3xl p-2 px-10 m-2 hover:border-red-300 hover:text-red-300">Download</button>
                        <button class="border border-solid invisible  rounded-3xl p-2 px-10 m-2 hover:border-green-300 hover:text-green-300">Zoom</button>
                    </div>
            </div>
        </div>    
    </div>
    <Steper :steps="['a', 'b', 'c']" />
    <div class="mt-40">
        <div class="text-2lg text-red-800 flex justify-center">FAQ</div>
        <div class="text-3xl mt-4 p-4 flex justify-center">Do YOU HAVE A QUESTIONS?</div>
        <Disclosure title="Disclosure title">this is disclosure contents </Disclosure>
        <Disclosure title="Disclosure title">this is disclosure contents </Disclosure>
        <Disclosure title="Disclosure title">this is disclosure contents </Disclosure>
        <Disclosure title="Disclosure title">this is disclosure contents </Disclosure>
    </div>
    <UNotifications /> 
</div>
</template>