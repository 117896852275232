<script setup>

</script>

<template>
    <div class="max-w-screen-lg mx-auto mt-40">
        <div class="flex justify-center text-3xl">How TO USE IT</div>
        <ul>
            <li class="flex items-center p-8 ">
                <span class="
                before:border
                before:border-dashed
              before:border-red-200
                before:absolute
                before:top-12
                before:-bottom-16
                relative
                font-mono
                text-lg
                text-white
                bg-red-200
                 border 
                 border-solid
                 border-red-200 
                 size-16 
                 rounded-full 
                 flex 
                 items-center 
                 justify-center ">
                 STEP 1
                </span>
                <div class="pl-16">
                   upload source photo
                </div>
            </li>
            <li class="flex items-center p-8 ">
                <span class="
                before:border
                before:border-dashed
              before:border-red-200
                before:absolute
                before:top-12
                before:-bottom-16
                relative
                font-mono
                text-lg
                text-white
                bg-red-200
                 border 
                 border-solid
                 border-red-200 
                 size-16 
                 rounded-full 
                 flex 
                 items-center 
                 justify-center ">
                 STEP 2
                </span>
                <div class="pl-16">
                   upload source photo
                </div>
            </li>
            <li class="flex items-center p-8 ">
                <span class="
                
                relative
                font-mono
                text-lg
                text-white
                bg-red-200
                 border 
                 border-solid
                 border-red-200 
                 size-16 
                 rounded-full 
                 flex 
                 items-center 
                 justify-center ">
                 STEP 3
                </span>
                <div class="pl-16">
                  click " Swap"
                </div>
            </li>
            
        </ul>
    </div>
</template>